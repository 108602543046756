<template>
    <v-container fluid>
        <v-row>
            <v-col
                class="d-flex"
                cols="12"
                sm="6"
            >
                <v-select
                    :items="items"
                    label="เลือกคณะ"
                    solo
                ></v-select>
            </v-col>
        </v-row>
        <v-card class="mb-5">
            <v-card-text>
                <v-row>
                    <v-col cols="1" class="my-auto" style="white-space: nowrap;">
                        <h1>Import</h1>
                    </v-col>
                    <v-col
                        class="my-auto"
                        cols="3"
                    >
                        <v-select
                            :items="items"
                            label="รูปแบบการ import"
                            solo
                            dense
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col class="my-auto">
                        <v-btn>
                            Download Template
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <div @drop="dropFile" @dragenter.prevent @dragover.prevent @click="$refs.file.click()" style="cursor: pointer;">
                            <v-card style="height:200px;border: 5px dashed grey;" flat>
                                <input type="file" multiple @change="uploadFile" style="display:none" ref="file"/>
                                <v-card-text>
                                    <div class="vertical-horizontal-center">
                                        <h1>Drag and drop .csv files</h1> 
                                        <h3 class="text-center">or</h3>
                                        <h1 class="text-center"><b>Browse files</b></h1> 
                                    </div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-col>
                                <v-radio-group v-model="radioGroup">
                                    <v-radio
                                        v-for="n in importChoice"
                                        :key="n"
                                        :label="`${n}`"
                                        :value="n"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn block>
                                    Import
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <my-course class="pt-5" :courseLists="courseLists"/>
    </v-container>
</template>

<script>
import MyCourse from '../../components/MyCourse'

export default {
    name: 'AdminImport',
    components: {
        MyCourse
    },
    data() {
        return {
            items: ['วิทยาลัยศิลปะ สื่อ และเทคโนโลยี'],
            File: [],
            radioGroup: 1,
            courseLists: [],
            importChoice: ['Create new only', 'Create and update', 'Update exist']
        }
    },
    created () {
    },
    methods: {
        uploadFile(e) {
            this.File = e.target.files;
        },
        dropFile (e) {
            e.preventDefault()
            this.File = e.dataTransfer.files;
            console.log("file")
        }
    },
}


</script>